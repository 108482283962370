import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  hiddenPropsSDKFactory,
  elementPropsSDKFactory,
  collapsedPropsSDKFactory,
  changePropsSDKFactory,
} from '../props-factories';
import { menuPropsSDKFactory } from './menuItemsPropsSDKFactory';
import { IMenuSDK, MenuProps } from './Menu.types';

export const sdk = composeSDKFactories<MenuProps, IMenuSDK>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  changePropsSDKFactory,
  menuPropsSDKFactory,
);
