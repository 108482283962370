import {
  withValidation,
  composeSDKFactories,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import {
  changePropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  createRequiredPropsSDKFactory,
  createValidationPropsSDKFactory,
  disablePropsSDKFactory,
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IRatingsInputProps,
  IRatingsInputImperativeActions,
  IRatingsInputSDK,
  IRatingsInputOwnSDKFactory,
  IIconMouseInCorvidEvent,
} from '../RatingsInput.types';
import {
  composeValidators,
  createInputValidator,
  InputValidator,
  validateRequired,
} from '../../../core/corvid/inputUtils';

const ratingsInputValidator: InputValidator<
  IRatingsInputProps,
  IRatingsInputImperativeActions
> = createInputValidator(
  composeValidators<IRatingsInputProps>([validateRequired]),
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  ratingsInputValidator,
);

const validationPropsSDKFactory = createValidationPropsSDKFactory(
  ratingsInputValidator,
);

const _ratingsInputPropsSKDFactory: IRatingsInputOwnSDKFactory = api => {
  const {
    setProps,
    props,
    registerEvent,
    metaData,
    getSdkInstance,
    create$w,
  } = api;
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    get value() {
      return props.value;
    },
    get type() {
      return '$w.RatingsInput';
    },
    set value(value) {
      setProps({ value: value || null });

      ratingsInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },

    onIconMouseIn(handler) {
      if (!functionValidator(handler, 'onIconMouseIn')) {
        return getSdkInstance();
      }
      registerEvent<IIconMouseInCorvidEvent>('iconMouseIn', event => {
        const $w = create$w();
        handler(event, $w);
      });
      return getSdkInstance();
    },
  };
};

const ratingsInputPropsSKDFactory = withValidation(
  _ratingsInputPropsSKDFactory,
  {
    type: ['object'],
    properties: {
      value: {
        type: ['integer', 'nil'],
        maximum: 5,
        minimum: 1,
      },
    },
  },
);

export const sdk = composeSDKFactories<IRatingsInputProps, IRatingsInputSDK>(
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  focusPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  ratingsInputPropsSKDFactory,
);
