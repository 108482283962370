import {
  CorvidSDKPropsFactory,
  ICleanedCorvidEvent,
  CorvidEventHandler,
} from '@wix/editor-elements-types';
import {
  createCompSchemaValidator,
  convertToCorvidEvent,
} from '@wix/editor-elements-corvid-utils';

export interface IFocusPropSDKProps {}

export interface IFocusPropSDK {
  focus: () => void;
  blur: () => void;
  onFocus: (handler: CorvidEventHandler) => void;
  onBlur: (handler: CorvidEventHandler) => void;
}

export interface IFocusPropsSDKActions {
  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
}

export interface IFocusPropsImperativeActions {
  focus: () => void;
  blur: () => void;
}

export const focusPropsSDKFactory: CorvidSDKPropsFactory<
  IFocusPropSDKProps,
  IFocusPropSDK,
  IFocusPropsImperativeActions
> = ({ registerEvent, compRef, metaData, getSdkInstance, create$w }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    focus() {
      compRef.focus();
    },
    blur() {
      compRef.blur();
    },
    onFocus(handler) {
      if (!functionValidator(handler, 'onFocus')) {
        return getSdkInstance();
      }
      registerEvent<ICleanedCorvidEvent<React.FocusEvent>>('onFocus', event => {
        const corvidEvent = convertToCorvidEvent(event);
        const $w = create$w();
        handler(corvidEvent, $w);
      });

      return getSdkInstance();
    },
    onBlur(handler) {
      if (!functionValidator(handler, 'onBlur')) {
        return getSdkInstance();
      }
      registerEvent<ICleanedCorvidEvent<React.FocusEvent>>('onBlur', event => {
        const corvidEvent = convertToCorvidEvent(event);
        const $w = create$w();
        handler(corvidEvent, $w);
      });
      return getSdkInstance();
    },
  };
};
