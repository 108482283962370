const cloneDate = (date: Date) => new Date(date);

export const getStartOfDay = (date: Date): Date => {
  const newDate = cloneDate(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const areDatesEqual = (date1: Date, date2: Date): boolean =>
  date1.getTime() === date2.getTime();

export const getGridOfDaysInMonth = (
  year: number,
  month: number,
  weekStartDay: number,
): Array<Array<number | undefined>> => {
  const firstDayInMonth = new Date(year, month);
  const lastDayInMonth = new Date(year, month + 1, 0).getDate();

  const firstDayInMonthWeekDay = firstDayInMonth.getDay();

  // We need to shift the days in the first week of the month relative to the starting day of the week. For example:
  // 1st of month is Tuesday and the week starts with Sunday (no shift):
  // [undefined, undefined, 1, 2, 3, 4, 5]
  // 1st of month is Tuesday and the week starts with Monday (shift by one):
  // [undefined, 1, 2, 3, 4, 5, 6]
  // 1st of month is Monday and the week starts with Tuesday (Monday is the last day of the week):
  // [undefined, undefined, undefined, undefined, undefined, undefined, 1]
  // 1st of month is Tuesday and the week starts with Tuesday (shift by two):
  // [1, 2, 3, 4, 5, 6, 7]
  const shiftedFirstDayInMonthWeekDay =
    firstDayInMonthWeekDay -
    weekStartDay +
    (firstDayInMonthWeekDay < weekStartDay ? 7 : 0);

  const daysInFirstWeek = 7 - shiftedFirstDayInMonthWeekDay;

  const firstWeek = [
    ...Array(shiftedFirstDayInMonthWeekDay),
    ...Array(daysInFirstWeek)
      .fill(0)
      .map((_, index) => index + 1),
  ];

  const weeks = [firstWeek];

  const addWeek = (traversedDaysInMonth: number) => {
    const remainingDaysInMonth = lastDayInMonth - traversedDaysInMonth;
    if (remainingDaysInMonth > 7) {
      const week = Array(7)
        .fill(0)
        .map((_, index) => traversedDaysInMonth + index + 1);
      weeks.push(week);
      addWeek(traversedDaysInMonth + 7);
    } else {
      const week = [
        ...Array(remainingDaysInMonth)
          .fill(0)
          .map((_, index) => traversedDaysInMonth + index + 1),
        ...Array(7 - remainingDaysInMonth),
      ];
      weeks.push(week);
    }
  };

  addWeek(daysInFirstWeek);

  return weeks;
};
