import { withValidation } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import { convertGalleryItemsToUserModel } from '../../galleries/GallerySDKUtils';
import {
  ItemUserModel,
  GalleryItemProps,
} from '../../galleries/GalleriesSDK.types';

export interface IGalleryOnItemClickedPropsSDKProps {
  items: Array<GalleryItemProps>;
}
export interface IGalleryOnItemClickedOwnSDKProps {
  onItemClicked(handler: (event: OnItemClickedEvent) => void): void;
}

export type OnItemClickedEvent = {
  item: ItemUserModel;
  itemIndex: number;
  type: string;
};

const _onItemClickSDKFactory: CorvidTypes.CorvidSDKFactory<
  IGalleryOnItemClickedPropsSDKProps,
  IGalleryOnItemClickedOwnSDKProps
> = ({ props, registerEvent, linkUtils, getSdkInstance }) => {
  return {
    onItemClicked(handler) {
      registerEvent('onItemClicked', (event: OnItemClickedEvent) => {
        const convertedItemToUserModel = convertGalleryItemsToUserModel(
          [props.items[event.itemIndex]],
          linkUtils,
        );

        [event.item] = convertedItemToUserModel;
        handler(event);
      });

      return getSdkInstance();
    },
  };
};

export const onItemClickSDKFactory = withValidation(_onItemClickSDKFactory, {
  type: ['object'],
  properties: {
    onItemClicked: {
      type: ['function'],
      args: [{ type: ['function'] }],
    },
  },
});
