import {
  composeSDKFactories,
  withValidation,
  createCompSchemaValidator,
  convertToCorvidEvent,
} from '@wix/editor-elements-corvid-utils';
import { ICleanedCorvidEvent } from '@wix/editor-elements-types';
import {
  ICaptchaProps,
  ICaptchaSDK,
  ICaptchaOwnSDKFactory,
} from '../Captcha.types';
import {
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

const _ownSDKFactory: ICaptchaOwnSDKFactory = api => {
  const {
    props,
    compRef,
    registerEvent,
    metaData,
    getSdkInstance,
    create$w,
  } = api;
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    blur() {},
    get token() {
      return props.token;
    },
    reset() {
      compRef.reset();
    },
    onError(handler) {
      if (!functionValidator(handler, 'onError')) {
        return getSdkInstance();
      }
      registerEvent<ICleanedCorvidEvent>('onError', event => {
        const corvidEvent = convertToCorvidEvent(event);
        const $w = create$w();
        handler(corvidEvent, $w);
      });
      return getSdkInstance();
    },
    onTimeout(handler) {
      if (!functionValidator(handler, 'onTimeout')) {
        return getSdkInstance();
      }
      registerEvent<ICleanedCorvidEvent>('onTimeout', event => {
        const corvidEvent = convertToCorvidEvent(event);
        const $w = create$w();
        handler(corvidEvent, $w);
      });
      return getSdkInstance();
    },
    onVerified(handler) {
      if (!functionValidator(handler, 'onVerified')) {
        return getSdkInstance();
      }
      registerEvent<ICleanedCorvidEvent>('onVerified', event => {
        const corvidEvent = convertToCorvidEvent(event);
        const $w = create$w();
        handler(corvidEvent, $w);
      });
      return getSdkInstance();
    },
    toJSON() {
      const { token } = props;
      return {
        ...toJSONBase(metaData),
        token,
      };
    },
  };
};

const ownSDKFactory = withValidation(_ownSDKFactory, {
  type: ['object'],
  properties: {},
});

export const sdk = composeSDKFactories<ICaptchaProps, ICaptchaSDK>(
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  ownSDKFactory,
);
