import {
  CorvidSDKPropsFactory,
  CorvidEventHandler,
  ICleanedCorvidEvent,
} from '@wix/editor-elements-types';
import {
  createCompSchemaValidator,
  convertToCorvidEvent,
} from '@wix/editor-elements-corvid-utils';

export interface IChangePropsSDKProps {}

export interface IChangePropsSDK {
  onChange: (handler: CorvidEventHandler) => void;
}

export interface IChangePropsSDKActions<T = Element> {
  onChange?: (event: React.ChangeEvent<T>) => void;
}

export const changePropsSDKFactory: CorvidSDKPropsFactory<
  IChangePropsSDKProps,
  IChangePropsSDK
> = ({ registerEvent, metaData, getSdkInstance, create$w }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );

  return {
    onChange(handler) {
      if (!functionValidator(handler, 'onChange')) {
        return getSdkInstance();
      }

      registerEvent<ICleanedCorvidEvent<React.ChangeEvent>>(
        'onChange',
        event => {
          const cleaned = convertToCorvidEvent(event);
          const $w = create$w();
          handler(cleaned, $w);
        },
      );
      return getSdkInstance();
    },
  };
};
