import {
  withValidation,
  assert,
  composeSDKFactories,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import {
  IGoogleMapSDKFactory,
  GoogleMapSDKFactory,
  GoogleMapMarkerClickedEvent,
  GoogleMapClickedEvent,
} from '../GoogleMap.types';
import {
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import { isLocationObject, areMarkersValid } from './validation';
import { getLinkObject } from './utils';

export const _googleMapSDKFactory: GoogleMapSDKFactory = ({
  setProps,
  props,
  linkUtils,
  compRef,
  registerEvent,
  metaData,
  getSdkInstance,
  create$w,
}) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );

  const _state = {
    center: {
      latitude: props.mapData.locations[props.mapData.defaultLocation].latitude,
      longitude:
        props.mapData.locations[props.mapData.defaultLocation].longitude,
    },
    zoom: props.mapData.zoom,
  };
  registerEvent(
    'onUpdateCenter',
    (value: { latitude: number; longitude: number }) =>
      (_state.center = {
        latitude: value.latitude,
        longitude: value.longitude,
      }),
  );
  registerEvent(
    'onUpdateZoom',
    (value: { zoom: number }) => (_state.zoom = value.zoom),
  );
  return {
    get location() {
      const { mapData } = props;
      return {
        latitude: mapData.locations[0].latitude,
        longitude: mapData.locations[0].longitude,
        description: mapData.locations[0].title,
      };
    },

    set location(location) {
      const { mapData } = props;
      const newMapData = { ...mapData };

      newMapData.locations[0] = {
        ...mapData.locations[0],
        title: assert.isNil(location.description) ? '' : location.description,
        latitude: location.latitude || mapData.locations[0].latitude,
        longitude: location.longitude || mapData.locations[0].longitude,
      };

      setProps({ mapData: newMapData });
    },

    get markers() {
      const { mapData } = props;
      return mapData.locations.map(location => ({
        address: location.address,
        location: {
          longitude: location.longitude,
          latitude: location.latitude,
        },
        icon: location.pinIcon,
        link: assert.isObject(location.locationLinkAttributes)
          ? location.locationLinkAttributes.href
          : null,
        title: location.title,
        linkTitle: location.linkTitle,
        description: location.description,
      }));
    },

    set markers(markers) {
      const { mapData } = props;
      const newMapData = { ...mapData };

      newMapData.locations = markers.map(marker => {
        const resolvedLink = assert.isString(marker.link)
          ? getLinkObject(marker.link)
          : null;

        return {
          address: marker.address || '',
          latitude: marker.location?.latitude || NaN,
          longitude: marker.location?.longitude || NaN,
          title: marker.title || '',
          description: marker.description || '',
          link: resolvedLink,
          linkTitle: marker.linkTitle || '',
          pinIcon: marker.icon || '',
          pinColor: '',
          locationLinkAttributes: resolvedLink
            ? linkUtils.getLinkProps(resolvedLink.url, resolvedLink.target)
            : {},
        };
      });

      setProps({ mapData: newMapData });
    },

    setCenter({ longitude, latitude }) {
      if (!isLocationObject({ longitude, latitude })) {
        return;
      }
      return compRef.setMapCenter(longitude, latitude);
    },

    get center() {
      return _state.center;
    },

    setZoom(zoom) {
      return compRef.setMapZoom(zoom);
    },

    get zoom() {
      return _state.zoom;
    },

    onMarkerClicked(cb) {
      if (!functionValidator(cb, 'onMarkerClicked')) {
        return getSdkInstance();
      }
      registerEvent<GoogleMapMarkerClickedEvent>('onMarkerClicked', event => {
        const $w = create$w();
        cb(event, $w);
      });
      return getSdkInstance();
    },

    onMapClicked(cb) {
      if (!functionValidator(cb, 'onMapClicked')) {
        return getSdkInstance();
      }
      registerEvent<GoogleMapClickedEvent>('onMapClicked', event => {
        const $w = create$w();
        cb(event, $w);
      });
      return getSdkInstance();
    },

    getVisibleMarkers() {
      return compRef.getVisibleMarkers();
    },

    toJSON() {
      const { mapData } = props;
      return {
        ...toJSONBase(metaData),
        location: {
          latitude: mapData.locations[0].latitude,
          longitude: mapData.locations[0].longitude,
          description: mapData.locations[0].title,
        },
      };
    },
  };
};

const latitudeSchema = {
  type: ['number' as const],
  maximum: 90,
  minimum: -90,
  warnIfNil: true,
};

const longitudeSchema = {
  type: ['number' as const],
  maximum: 180,
  minimum: -180,
  warnIfNil: true,
};

const googleMapSDKFactory = withValidation(
  _googleMapSDKFactory,
  {
    type: ['object'],
    properties: {
      location: {
        type: ['object'],
        properties: {
          latitude: latitudeSchema,
          longitude: longitudeSchema,
          description: {
            type: ['string', 'nil'],
            warnIfNil: true,
          },
        },
      },
      setCenter: {
        type: ['function'],
        args: [
          {
            type: ['object'],
            properties: {
              latitude: latitudeSchema,
              longitude: longitudeSchema,
            },
          },
        ],
      },
      setZoom: {
        type: ['function'],
        args: [{ type: ['number'] }],
      },
    },
  },
  {
    location: [isLocationObject],
    markers: [areMarkersValid],
  },
);

export const sdk: IGoogleMapSDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  googleMapSDKFactory,
);
