import { SyntheticEvent } from 'react';
import {
  CorvidSDKPropsFactory,
  ICorvidSelector,
  SdkInstance,
} from '@wix/editor-elements-types';
import {
  createCompSchemaValidator,
  withValidation,
} from '@wix/editor-elements-corvid-utils';

export interface PauseEvent<T = Element> extends SyntheticEvent<T> {
  target: EventTarget & { type: 'autoplayOff' };
}

export interface PlayEvent<T = Element> extends SyntheticEvent<T> {
  target: EventTarget & { type: 'autoplayOn' };
}

type PlayEventHandler = (e: PlayEvent) => void;
type PauseEventHandler = (e: PauseEvent) => void;

type CorvidPlayEventHandler = (
  e: PlayEvent,
  corvidSelector: ICorvidSelector,
) => void;
type CorvidPauseEventHandler = (
  e: PauseEvent,
  corvidSelector: ICorvidSelector,
) => void;

export interface IPlayablePropsSDKActions {
  play: () => void;
  pause: () => void;
  isPlaying?: boolean;
  onPlay?: PlayEventHandler;
  onPause?: PauseEventHandler;
}

export interface IPlayablePropsSDKRefActions {
  play: () => void;
  pause: () => void;
  next: () => void;
  previous: () => void;
}

export type IPlayableControllerActions = {
  play: () => void;
  pause: () => void;
};

export interface IPlayablePropsSDKProps {
  isPlaying: boolean;
}

export interface IPlayableCommonPropsSDK {
  next: () => Promise<Element>;
  previous: () => Promise<Element>;
  isPlaying: boolean;
}

export interface IPlayablePropsSDK extends IPlayableCommonPropsSDK {
  play: () => void;
  pause: () => void;
  onPlay: (cb: PlayEventHandler) => void;
  onPause: (cb: PauseEventHandler) => void;
}

const _playablePropsSDKFactory: CorvidSDKPropsFactory<
  IPlayablePropsSDKProps,
  IPlayablePropsSDK
> = ({ props, registerEvent, compRef, metaData, getSdkInstance, create$w }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    get isPlaying() {
      return props.isPlaying;
    },
    play() {
      compRef.play();
      return getSdkInstance();
    },
    pause() {
      compRef.pause();
      return getSdkInstance();
    },
    onPlay(cb: CorvidPlayEventHandler) {
      if (!functionValidator(cb, 'onPlay')) {
        return getSdkInstance();
      }
      registerEvent('onPlay', (event: PlayEvent) => {
        cb(event, create$w());
      });
      return getSdkInstance();
    },
    onPause(cb: CorvidPauseEventHandler) {
      if (!functionValidator(cb, 'onPause')) {
        return getSdkInstance();
      }
      registerEvent('onPause', (event: PauseEvent) => {
        cb(event, create$w());
      });
      return getSdkInstance();
    },
    next() {
      return new Promise<SdkInstance>((resolve, reject) => {
        reject('sdk method not implemented');
      });
    },
    previous() {
      return new Promise<SdkInstance>((resolve, reject) => {
        reject('sdk method not implemented');
      });
    },
  };
};

export const playablePropsSDKFactory = withValidation(
  _playablePropsSDKFactory,
  {
    type: ['object'],
    properties: {},
  },
);
