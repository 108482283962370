import {
  withValidation,
  composeSDKFactories,
  assert,
  reportError,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import { LinkProps } from '@wix/thunderbolt-components';
import {
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  clickPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  IGridOwnSDKFactory,
  IGridProps,
  IGridSDK,
  GridLoadRowsEvent,
  GridDataChangeHandler,
  IGridDataChangeEvent,
  GridRowSelectHandler,
  TableCellEvent,
  TableRowEvent,
  ICellSelectEvent,
  IRowSelectEvent,
} from '../Grid.types';
import { DataSource, PaginationType, ColumnType } from '../constants';
import {
  extractImageDataFromSrc,
  extractHtmlTagAttributes,
  getRowValue,
  setRowValue,
  isValidDate,
  getPageRowsRange,
  getLinkPropsPath,
  getRichTextHtmlPath,
} from '../utils';

type GridSDKRows = IGridSDK['rows'];
type GridSDKColumns = IGridSDK['columns'];
type GridSDKRow = GridSDKRows[number];
type GridSDKColumn = GridSDKColumns[number];

const ownSDKFactory: IGridOwnSDKFactory = ({
  setProps,
  registerEvent,
  createEvent,
  getSdkInstance,
  create$w,
  props,
  metaData,
  linkUtils,
}) => {
  const sdkType = '$w.Table';
  const $w = create$w();
  const dataChangeHandlers: Array<GridDataChangeHandler> = [];
  const rowSelectHandlers: Array<GridRowSelectHandler> = [];

  let dataFetcher: IGridSDK['dataFetcher'] = null;
  let lastDataFetcherRequestId = 0;
  let lastDataChangeEventTime = Date.now();

  const schemaValidator = createCompSchemaValidator(metaData.role);
  const validateEventHandler = (handler: unknown, name: string) =>
    schemaValidator(handler, { type: ['function'] }, name);

  const triggerDataChangeHandlers = () => {
    if (!dataChangeHandlers.length) {
      return;
    }

    const now = Date.now();

    // Emulate old behavior for backwards compatibility where data change events
    // are not triggered unless Date.now() has changed since last update.
    if (lastDataChangeEventTime < now) {
      lastDataChangeEventTime = now;

      const event = createEvent({ type: 'dataChange' }) as IGridDataChangeEvent;
      dataChangeHandlers.forEach(handler => handler(event, $w));
    }
  };

  const transformRowUpdateIndex = (rowIndex: number): number => {
    const { dataSource, pagination, currentPage } = props;

    if (
      dataSource === DataSource.Static ||
      pagination.type === PaginationType.Scroll
    ) {
      return rowIndex;
    }

    if (pagination.type === PaginationType.Pages) {
      const [startRow, endRow] = getPageRowsRange(
        currentPage ?? 1,
        pagination.rowsPerPage,
      );

      if (rowIndex >= startRow && rowIndex < endRow) {
        // With a fixed pagination and dynamic data source the update index has
        // to be transformed from absolute to relative index for current page.
        return rowIndex - startRow;
      }
    }

    return -1;
  };

  const processRowWithImageColumn = (
    row: GridSDKRow,
    { dataPath }: GridSDKColumn,
  ) => {
    const src = getRowValue(row, dataPath);
    if (src) {
      const imageData = extractImageDataFromSrc(src);
      if (imageData) {
        setRowValue(row, dataPath!, imageData);
      }
    }
  };

  const processRowWithDateColumn = (
    row: GridSDKRow,
    { dataPath }: GridSDKColumn,
  ) => {
    const dateValue = getRowValue(row, dataPath);
    if (!assert.isNil(dateValue)) {
      const date = new Date(dateValue);
      if (!isValidDate(date)) {
        reportError(
          `Error: Invalid date: "${dateValue}" in column '"${dataPath}"'`,
        );
      }
    }
  };

  const processRowWithLinkColumn = (
    row: GridSDKRow,
    { linkPath }: GridSDKColumn,
  ) => {
    const url = getRowValue(row, linkPath);
    let linkProps: LinkProps = {};

    try {
      linkProps = linkUtils.getLinkProps(url, props.linkTarget);
    } catch (ex) {
      if (url) {
        reportError(
          `Error: couldn't process link with url "${url}": ${ex.message}`,
        );
      }
    }

    setRowValue(row, getLinkPropsPath(linkPath!), linkProps);
  };

  const processRowWithRichTextColumn = (
    row: GridSDKRow,
    { dataPath }: GridSDKColumn,
  ) => {
    const html = getRowValue(row, dataPath);
    if (typeof html !== 'string' || !html) {
      return;
    }

    // Patch up inline links to respect linkTarget and have correct rel attributes
    const updatedHtml = html.replace(
      /(<a\s+)([^>]+)/gi,
      (match, tagPrefix, attributesHtml) => {
        const attributes = extractHtmlTagAttributes(attributesHtml);
        let linkProps: LinkProps = {};

        if (attributes.href) {
          try {
            linkProps = linkUtils.getLinkProps(
              attributes.href,
              props.linkTarget,
            );
          } catch {}
        }

        const { href, target, rel } = linkProps;
        const mergedAttributes = {
          ...attributes,
          ...(href && { href }),
          ...(target && { target }),
          ...(rel && { rel }),
        };

        const updatedAttributesHtml = Object.entries(mergedAttributes)
          .map(([name, value]) => `${name}="${value}"`)
          .join(' ');

        return `${tagPrefix}${updatedAttributesHtml}`;
      },
    );

    setRowValue(row, getRichTextHtmlPath(dataPath), updatedHtml);
  };

  const processNewRows = (rows: GridSDKRows) => {
    const { columns } = props;
    const dateColumns = columns.filter(({ type }) => type === ColumnType.Date);
    const linkColumns = columns.filter(({ linkPath }) => !!linkPath);
    const imageColumns = columns.filter(
      ({ type }) => type === ColumnType.Image,
    );
    const richTextColumns = columns.filter(
      ({ type }) => type === ColumnType.RichText,
    );

    return rows.map(row => {
      const newRow = { ...row };

      imageColumns.forEach(column => processRowWithImageColumn(newRow, column));
      dateColumns.forEach(column => processRowWithDateColumn(newRow, column));
      linkColumns.forEach(column => processRowWithLinkColumn(newRow, column));
      richTextColumns.forEach(column =>
        processRowWithRichTextColumn(newRow, column),
      );

      return newRow;
    });
  };

  const loadRows = async (
    startRow: number,
    endRow: number,
    isFirstLoad = false,
  ) => {
    if (!dataFetcher) {
      return;
    }

    const paginationType = props.pagination.type;
    const pagination = paginationType === PaginationType.None && {
      ...props.pagination,
      type: PaginationType.Scroll,
    };

    setProps({
      isLoading: true,
      dataSource: DataSource.Dynamic,
      ...(pagination && { pagination }),
      ...(isFirstLoad && {
        rows: [],
        currentPage: 1,
      }),
    });

    const requestId = ++lastDataFetcherRequestId;
    const { pageRows, totalRowsCount } = await dataFetcher(startRow, endRow);

    // UI can trigger new requests to load rows while there are still pending
    // requests in progress. To avoid updating UI with out of order data we
    // ignore stale dataFetcher results.
    if (requestId !== lastDataFetcherRequestId) {
      return;
    }

    const newRows = processNewRows(pageRows);
    const shouldAppend =
      !isFirstLoad && paginationType !== PaginationType.Pages;
    const rows = shouldAppend ? props.rows.concat(newRows) : newRows;

    setProps({
      isLoading: false,
      lastLoadedRowsCount: newRows.length,
      rows,
      totalRowsCount,
    });
    triggerDataChangeHandlers();
  };

  registerEvent<GridLoadRowsEvent>('onLoadRows', ({ startRow, endRow }) =>
    loadRows(startRow, endRow),
  );

  const triggerRowSelection = (data: TableRowEvent) => {
    const event = {
      ...createEvent({ type: 'rowSelect' }),
      ...data,
    } as IRowSelectEvent;
    rowSelectHandlers.forEach(handler => handler(event, $w));
  };

  registerEvent<TableRowEvent>('onRowSelect', data => {
    triggerRowSelection(data);
  });

  return {
    set pagination(value) {
      setProps({ pagination: { ...value } });
    },
    get pagination() {
      return { ...props.pagination };
    },
    set columns(value) {
      const columns = assert.isArray(value)
        ? value.map(column => ({ ...{ visible: true }, ...column }))
        : [];

      setProps({ columns });
    },
    get columns() {
      return props.columns.map(column => ({ ...column }));
    },
    set rows(value) {
      const rows = assert.isArray(value) ? value : [];

      // Ignore any pending requests from previous dynamic data source
      lastDataFetcherRequestId = 0;

      setProps({
        isLoading: false,
        dataSource: DataSource.Static,
        rows: processNewRows(rows),
      });
      triggerDataChangeHandlers();
    },
    get rows() {
      return props.rows.map(row => ({ ...row }));
    },
    set dataFetcher(value) {
      dataFetcher = value;
      const { rowsPerPage } = props.pagination;
      const [startRow, endRow] = getPageRowsRange(1, rowsPerPage);
      loadRows(startRow, endRow, true);
    },
    get dataFetcher() {
      return dataFetcher;
    },
    selectRow(selectedRow) {
      const { rows } = props;
      const isValidRowIndex = schemaValidator(
        selectedRow,
        {
          type: ['integer'],
          minimum: 0,
          maximum: rows.length - 1,
        },
        'selectRow',
      );

      if (!isValidRowIndex) {
        return;
      }

      const rowData = rows[selectedRow];
      setProps({ selectedRow, selectedCell: undefined });
      triggerRowSelection({ rowIndex: selectedRow, rowData });
    },
    updateRow(index, rowData) {
      const updateIndex = transformRowUpdateIndex(index);
      if (updateIndex < 0) {
        return;
      }

      const [updatedRow] = processNewRows([rowData]);
      const updatedRows = props.rows;

      updatedRows.splice(updateIndex, 1, updatedRow);
      setProps({ rows: updatedRows });
      triggerDataChangeHandlers();
    },
    onRowSelect(handler) {
      if (!validateEventHandler(handler, 'onRowSelect')) {
        return getSdkInstance();
      }

      rowSelectHandlers.push(handler);
      return getSdkInstance();
    },
    onCellSelect(handler) {
      if (!validateEventHandler(handler, 'onCellSelect')) {
        return getSdkInstance();
      }

      registerEvent<TableCellEvent>('onCellSelect', data => {
        const event = {
          ...data,
          type: 'cellSelect',
        } as ICellSelectEvent;
        handler(event, $w);
      });

      return getSdkInstance();
    },
    refresh() {
      if (props.dataSource !== DataSource.Dynamic) {
        return;
      }

      const { pagination, currentPage } = props;
      const { rowsPerPage } = pagination;
      let startRow: number;
      let endRow: number;
      let isFirstLoad = false;

      if (pagination.type === PaginationType.Pages) {
        [startRow, endRow] = getPageRowsRange(currentPage ?? 1, rowsPerPage);
      } else {
        [startRow, endRow] = getPageRowsRange(1, rowsPerPage);
        isFirstLoad = true;
      }

      loadRows(startRow, endRow, isFirstLoad);
    },
    onDataChange(handler) {
      if (!validateEventHandler(handler, 'onDataChange')) {
        return getSdkInstance();
      }

      dataChangeHandlers.push(handler);
      return getSdkInstance();
    },
    get type() {
      return sdkType;
    },
    toJSON() {
      const { pagination, columns, rows } = props;
      return {
        ...toJSONBase(metaData),
        type: sdkType,
        pagination,
        columns,
        rows,
      };
    },
  };
};

const ownSDKFactoryWithValidation = withValidation(ownSDKFactory, {
  type: ['object'],
  properties: {
    pagination: {
      type: ['object'],
      properties: {
        type: {
          type: ['string'],
          enum: Object.values(PaginationType),
        },
        rowsPerPage: {
          type: ['number'],
        },
      },
      required: ['type'],
    },
    columns: {
      type: ['array', 'nil'],
      warnIfNil: true,
      items: {
        type: ['object'],
        properties: {
          id: { type: ['string'] },
        },
        required: ['id'],
      },
    },
    rows: {
      type: ['array', 'nil'],
      warnIfNil: true,
      items: {
        type: ['object'],
      },
    },
    dataFetcher: {
      type: ['function', 'nil'],
    },
    updateRow: {
      type: ['function'],
      args: [
        { type: ['integer'], name: 'rowIndex' },
        { type: ['object'], name: 'row' },
      ],
    },
    selectRow: {
      type: ['function'],
      args: [{ type: ['integer'], name: 'rowIndex' }],
    },
  },
});

export const sdk = composeSDKFactories<IGridProps, IGridSDK>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
  ownSDKFactoryWithValidation,
);
