import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { IAnchorSDKFactory } from '../Anchor.types';
import {
  basePropsSDKFactory,
  createViewportPropsSDKFactory,
} from '../../../core/corvid/props-factories';

const anchorSDKFactory: IAnchorSDKFactory = ({ props, metaData }) => ({
  get name() {
    return props.name;
  },
  toJSON() {
    return {
      id: metaData.compId,
      type: `$w.${metaData.compType}`,
      global: metaData.isGlobal(),
      rendered: metaData.isRendered(),
      name: props.name,
    };
  },
});

export const sdk: IAnchorSDKFactory = composeSDKFactories(
  basePropsSDKFactory,
  createViewportPropsSDKFactory(),
  anchorSDKFactory,
);
