import {
  composeSDKFactories,
  reportError,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import {
  IWPhotoProps,
  IWPhotoSDK,
  IWPhotoSDKFactory,
  IWPhotoOwnSDKFactory,
} from '../WPhoto.types';
import {
  elementPropsSDKFactory,
  clickPropsSDKFactoryWithUpdatePlatformHandler,
  linkPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

import { parseMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import { createMediaItemUri } from '../../../core/corvid/media/mediaItemUtils';

const IMAGE_CLICK_ACTIONS = {
  none: 'disabled',
  expand: 'zoomMode',
  link: 'goToLink',
  magnified: 'zoomAndPanMode',
} as const;
const IMAGE_CLICK_ACTIONS_INVERTED_MAP = {
  disabled: 'none',
  zoomMode: 'expand',
  goToLink: 'link',
  zoomAndPanMode: 'magnified',
} as const;
type IMAGE_CLICK_ACTIONS_KEYS = keyof typeof IMAGE_CLICK_ACTIONS;

const IMAGE_FIT_MODE_MAP = {
  fixedWidth: 'fitWidth',
  fit: 'fit',
  fill: 'fill',
} as const;
const IMAGE_FIT_MODE_INVERTED_MAP = {
  fitWidth: 'fixedWidth',
  fit: 'fit',
  fill: 'fill',
} as const;

const externalUrl = /(^https?)|(^data)|(^blob)|(^\/\/)/;
const _wPhotoSDKFactory: IWPhotoOwnSDKFactory = ({
  setProps,
  props,
  metaData,
}) => {
  const getSrc = () => {
    const { uri, height, width, title } = props;

    if (externalUrl.test(uri)) {
      return uri;
    }

    const mediaItemUri = createMediaItemUri({
      mediaId: uri,
      width,
      height,
      title,
      type: 'image',
    });
    if (mediaItemUri.error) {
      return '';
    }
    return mediaItemUri.item || '';
  };

  return {
    get src() {
      return getSrc();
    },
    set src(src: string) {
      if (!src) {
        setProps({ uri: '' });
      } else {
        const { height, width, title, mediaId, error } = parseMediaSrc(
          src,
          'image',
        );

        if (!error) {
          setProps({
            ...(height !== undefined ? { height } : {}),
            ...(width !== undefined ? { width } : {}),
            ...(title !== undefined ? { title } : {}),
            uri: mediaId,
            crop: null,
          });
        } else {
          reportError(
            `The "src" property cannot be set to "src". It must be a valid URL starting with "http://", "https://", or "wix:image://".`,
          );
        }
      }
    },
    get alt() {
      return props.alt;
    },
    set alt(alt) {
      setProps({ alt: alt || '' });
    },
    get tooltip() {
      return props.title;
    },
    set tooltip(tooltip) {
      setProps({ title: tooltip || '' });
    },
    get clickAction() {
      return IMAGE_CLICK_ACTIONS_INVERTED_MAP[props.onClickBehavior];
    },
    set clickAction(action: IMAGE_CLICK_ACTIONS_KEYS) {
      const onClickBehavior =
        IMAGE_CLICK_ACTIONS[action] || IMAGE_CLICK_ACTIONS.none;
      setProps({ onClickBehavior });
    },
    get fitMode() {
      /** This is here for parity with Bolt on what seems to be a bug:
       * the `displayMode` type specified in the Bolt implementation only
       * includes 'fit' | 'fill' | 'fitWidth'
       * while the type in the `WPhoto` component has more options.
       * There are tests for this behaviour on the setter as well
       */
      const displayMode: 'fit' | 'fill' | 'fitWidth' = props.displayMode as
        | 'fit'
        | 'fill'
        | 'fitWidth';
      return IMAGE_FIT_MODE_INVERTED_MAP[displayMode];
    },
    set fitMode(value) {
      setProps({ displayMode: IMAGE_FIT_MODE_MAP[value] });
    },

    get type() {
      return '$w.Image';
    },

    toJSON() {
      const elementProps = toJSONBase(metaData);

      return {
        ...elementProps,
        alt: props.alt,
        tooltip: props.title,
        src: getSrc(),
        type: '$w.Image',
      };
    },
  };
};

export const WPhotoSDKFactory: IWPhotoOwnSDKFactory = withValidation(
  _wPhotoSDKFactory,
  {
    type: ['object'],
    properties: {
      src: { type: ['string', 'nil'], warnIfNil: true },
      description: { type: ['string', 'nil'], warnIfNil: true },
      title: { type: ['string', 'nil'], warnIfNil: true },
      alt: { type: ['string', 'nil'], warnIfNil: true },
      tooltip: { type: ['string', 'nil'], warnIfNil: true },
      width: { type: ['integer'] },
      height: { type: ['integer'] },
      clickAction: {
        type: ['string', 'nil'],
        enum: Object.keys(IMAGE_CLICK_ACTIONS),
        warnIfNil: true,
      },
      fitMode: {
        type: ['string'],
        enum: Object.keys(IMAGE_FIT_MODE_MAP),
      },
    },
  },
);

export const sdk: IWPhotoSDKFactory = composeSDKFactories<
  IWPhotoProps,
  IWPhotoSDK
>(
  elementPropsSDKFactory,
  clickPropsSDKFactoryWithUpdatePlatformHandler,
  linkPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  WPhotoSDKFactory,
);
