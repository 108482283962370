import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import {
  changePropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  createRequiredPropsSDKFactory,
  createValidationPropsSDKFactory,
  createValuePropsSdkFactory,
  disablePropsSDKFactory,
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  readOnlyPropsSDKFactory,
  textInputPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  IRichTextBoxSDKFactory,
  IRichTextBoxProps,
  IRichTextBoxSDK,
  IRichTextBoxImperativeActions,
} from '../RichTextBox.types';
import {
  createInputValidator,
  InputValidator,
  composeValidators,
  validateRequired,
  composeSanitizers,
  numberToString,
  forceMaxLength,
  emptyStringIfNotString,
  Sanitizer,
} from '../../../core/corvid/inputUtils';

const richTextBoxValidator: InputValidator<
  IRichTextBoxProps,
  IRichTextBoxImperativeActions
> = createInputValidator(
  composeValidators<IRichTextBoxProps>([validateRequired]),
);

const valueSanitizer = ({ maxLength }: IRichTextBoxProps) => {
  const sanitizers: Array<Sanitizer> = [numberToString, emptyStringIfNotString];

  if (maxLength) {
    sanitizers.push(forceMaxLength(maxLength));
  }

  return composeSanitizers(sanitizers);
};

const validationPropsSDKFactory = createValidationPropsSDKFactory<
  IRichTextBoxProps,
  IRichTextBoxImperativeActions
>(richTextBoxValidator);

const valuePropsSDKFactory = createValuePropsSdkFactory<
  IRichTextBoxProps,
  IRichTextBoxImperativeActions
>(
  (value, props) => valueSanitizer(props)(value),
  { type: ['string'] },
  richTextBoxValidator,
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory<
  IRichTextBoxProps,
  IRichTextBoxImperativeActions
>(richTextBoxValidator);

const _richTextBox: IRichTextBoxSDKFactory = ({ props, metaData }) => ({
  toJSON() {
    const { required, readOnly, value } = props;
    return {
      ...toJSONBase(metaData),
      required,
      readOnly,
      value,
    };
  },
});

const richTextBox = withValidation(_richTextBox, {
  type: ['object'],
  properties: {},
});

export const sdk = composeSDKFactories<IRichTextBoxProps, IRichTextBoxSDK>(
  elementPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  readOnlyPropsSDKFactory,
  requiredPropsSDKFactory,
  textInputPropsSDKFactory,
  validationPropsSDKFactory,
  valuePropsSDKFactory,
  richTextBox,
);
