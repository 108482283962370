import {
  withValidation,
  composeSDKFactories,
  assert,
  reportError,
} from '@wix/editor-elements-corvid-utils';
import {
  IImageXProps,
  IImageXOwnSDKFactory,
  IImageXSDK,
} from '../ImageX.types';
import {
  linkPropsSDKFactory,
  clickPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import { parseMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';

const clickActionValues = {
  show: 'link',
  disable: 'none',
};

const _imageXSDKFactory: IImageXOwnSDKFactory = ({
  setProps,
  props,
  metaData,
}) => ({
  get src() {
    return props.defaultSrc;
  },
  set src(value) {
    const src = assert.isNil(value) ? '' : value;
    const { height, width, title: name, error, mediaId: uri } = parseMediaSrc(
      src,
      'image',
    );
    if (error) {
      reportError(
        `The "src" property cannot be set to "src". It must be a valid URL starting with "http://", "https://", or "wix:image://".`,
      );
      return;
    }
    const currentImageData = props.imageInfo.imageData;

    const updatedImageData = {
      ...currentImageData,
      width: width || currentImageData.width,
      height: height || currentImageData.height,
      uri: uri || currentImageData.uri,
      name: name || currentImageData.name,
      crop: null,
    };
    setProps({
      defaultSrc: src,
      imageInfo: {
        ...props.imageInfo,
        containerId: metaData.compId,
        imageData: updatedImageData,
        sourceSets: props.imageInfo.sourceSets.map(sourceSet => ({
          ...sourceSet,
          src: undefined,
        })),
      },
    });
  },

  get alt() {
    return props.imageInfo.imageData.alt;
  },

  set alt(value) {
    const altValue = assert.isNil(value) ? '' : value;
    setProps({
      imageInfo: {
        ...props.imageInfo,
        containerId: metaData.compId,
        imageData: { ...props.imageInfo.imageData, alt: altValue },
      },
    });
  },

  get clickAction() {
    return props.showLink ? clickActionValues.show : clickActionValues.disable;
  },

  set clickAction(value) {
    const newPropVal = clickActionValues.show === value;

    setProps({ showLink: newPropVal });
  },

  get name() {
    return props.imageInfo.imageData.name;
  },

  set name(value) {
    setProps({
      imageInfo: {
        ...props.imageInfo,
        containerId: metaData.compId,
        imageData: { ...props.imageInfo.imageData, name: value },
      },
    });
  },
  get type() {
    return '$w.Image';
  },
  toJSON() {
    return {
      ...toJSONBase(metaData),
      type: '$w.Image',
      alt: props.imageInfo.imageData.alt,
      src: props.defaultSrc,
      name: props.imageInfo.imageData.name,
    };
  },
});

const imageXSDKFactory = withValidation(_imageXSDKFactory, {
  type: ['object'],
  properties: {
    src: { type: ['string', 'nil'], warnIfNil: true },
    alt: { type: ['string', 'nil'], warnIfNil: true },
    name: { type: ['string', 'nil'], warnIfNil: true },
    clickAction: { type: ['string'], enum: Object.values(clickActionValues) },
  },
});

export const sdk = composeSDKFactories<IImageXProps, IImageXSDK>(
  elementPropsSDKFactory,
  linkPropsSDKFactory,
  clickPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  imageXSDKFactory,
);
